import React from "react";
import logo from "../../assets/images/logo.png";
import menuLeft from "../../assets/images/menu-left.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation } from "react-router-dom";

const AdminNav = () => {
  const { pathname } = useLocation();
  let validates = false;
  let user = JSON.parse(localStorage.getItem("LogUser"));
  let Fname = JSON.parse(localStorage.getItem("fname"));

  user ? (validates = true) : (validates = false);
  // Navbar titles
  const navArr = [
    { path: "/admin", title: "Dashboard" },
    { path: "/admin/testimonials", title: "Testimonial" },
    { path: "/admin/research", title: "Research" },
    { path: "/admin/Contact", title: "Contact Us" },
    { path: "/admin/editinfo", title: "Information" },
    { path: "/admin/userquery", title: "User Query" },
    { path: "/admin/award", title: "Awards" },
  ];
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md main-header-section">
          <div className="container-fluid">
            <a
              className="navbar-brand"
              href="/#"
              onClick={(e) => e.preventDefault()}
            >
              <div className="logoContent ">
                <img src={logo} alt="" className="img-fluid" />
              </div>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mynavbar"
            >
              <img src={menuLeft} alt="" />
            </button>
            <div className="collapse navbar-collapse" id="mynavbar">
              <ul className="navbar-nav ms-auto">
                {navArr.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        item.path === pathname
                          ? "nav-item text-underline"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link " to={item.path}>
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <div className="userTop ms-auto">
                <div className="dropdown">
                  {validates ? (
                    <>
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span>{Fname}</span>
                      </button>
                      <ul className="dropdown-menu">
                        <Link className="dropdown-item" to="/logout">
                          Logout
                        </Link>
                      </ul>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default AdminNav;
