import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer-sec">
        <center>
          {/* <div className="socialLinks">
          </div> */}
          {/* <div>
          </div> */}
          <div className="copyRight">
            <p>
              Readmax.com* is the sole owner of the information collected on
              this site. We will not sell, share or rent this information to any
              outside parties, except as outlined in the{" "}
              <Link className="copyRight" to="/privacypolicy">
                Privacy Policy
              </Link>
            </p>

            <span>CopyRight &copy; 2022 Readmax. All Right Reserved </span>
          </div>
        </center>
      </div>
    </>
  );
};
export default Footer;
