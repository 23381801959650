import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, lazy } from "react";
import Loader from "./Components/loader/Loader";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import setUpAxios from "./config/APIConfig";
import ManageGroup from "./Components/UserDashboard/ManageGroup";
import PaymentPage from "./Components/UserDashboard/PaymentPage";
import UpgradeSubscription from "./Components/UserDashboard/UpgradeSubscription";
import ChiildList from "./Components/Admin/childList/childList";
import NonAdminRoutes from "./routes/NonAdminRoutes";
// import { Transactions } from "./Components/Admin/Transactions";
import SpeedDials from "./Components/SpeedDial/SpeedDial";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Theme/theme";

const Transactions = lazy(() => import("./Components/Admin/Transactions"));

const Landing = lazy(() => import("./Components/Landing"));
const Register = lazy(() => import("./Components/Registration"));
const Login = lazy(() => import("./Components/Login"));
const RegistrationVerification = lazy(() =>
  import("./Components/RegistrationVerification")
);

const ForgetPass = lazy(() => import("./Components/ForgetPass"));
const ForgotVeryfyOtp = lazy(() => import("./Components/ForgotVeryfyOtp"));
const NewPassword = lazy(() => import("./Components/NewPassword"));
const Success = lazy(() => import("./Components/Success"));
const Dashboard = lazy(() => import("./Components/Dashboard"));
const UserProfile = lazy(() => import("./Components/UserProfile"));
const LogOut = lazy(() => import("./Components/LogOut"));
const Privacypolicy = lazy(() => import("./Components/Privacypolicy"));
const Setting = lazy(() => import("./Components/Setting"));
const Information = lazy(() =>
  import("./Components/UserDashboard/Information")
);
const Testimonials = lazy(() =>
  import("./Components/UserDashboard/Testimonials")
);
const Research = lazy(() => import("./Components/UserDashboard/Research"));
const UserSubscription = lazy(() =>
  import("./Components/UserDashboard/UserSubscription")
);
const ContactUs = lazy(() => import("./Components/UserDashboard/ContactUs"));
const Awards = lazy(() => import("./Components/UserDashboard/Awards"));

const UserList = lazy(() => import("./Components/Admin/UserList"));
const Tesimonials = lazy(() => import("./Components/Admin/Tesimonials"));
const EditResearch = lazy(() => import("./Components/Admin/EditResearch"));
const EditContact = lazy(() => import("./Components/Admin/EditContact"));
const EditInfo = lazy(() => import("./Components/Admin/EditInfo"));
const AddAward = lazy(() => import("./Components/Admin/AddAward"));
const UserAllQuery = lazy(() => import("./Components/Admin/UserAllQuery"));
const PageNotFound = lazy(() => import("./Components/PageNotFound"));

function App() {
  setUpAxios();

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ToastContainer />
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense fallback={<Loader />}>
                <Landing />
              </Suspense>
            }
          />
          <Route
            path="/registration"
            element={
              <Suspense fallback={<Loader />}>
                <Register />
              </Suspense>
            }
          />
          <Route
            path="/verifyEmail/:token"
            element={
              <Suspense fallback={<Loader />}>
                <RegistrationVerification />
              </Suspense>
            }
          />
          <Route
            path="/forget/password"
            element={
              <Suspense fallback={<Loader />}>
                <ForgetPass />
              </Suspense>
            }
          />
          <Route
            path="/forget/verify"
            element={
              <Suspense fallback={<Loader />}>
                <ForgotVeryfyOtp />
              </Suspense>
            }
          />
          <Route
            path="/update/newPassword/"
            element={
              <Suspense fallback={<Loader />}>
                <NewPassword />
              </Suspense>
            }
          />
          <Route
            path="/forget/success"
            element={
              <Suspense fallback={<Loader />}>
                <Success />
              </Suspense>
            }
          />
          <Route
            path="/logout"
            element={
              <Suspense fallback={<Loader />}>
                <LogOut />
              </Suspense>
            }
          />

          <Route path="/*" element={<NonAdminRoutes />} />

          <Route
            path="/admin"
            element={
              <Suspense>
                <UserList />
              </Suspense>
            }
          />
          <Route
            path="/admin/testimonials"
            element={
              <Suspense fallback={<Loader />}>
                <Tesimonials />
              </Suspense>
            }
          />
          <Route
            path="/admin/research"
            element={
              <Suspense fallback={<Loader />}>
                <EditResearch />
              </Suspense>
            }
          />
          <Route
            path="/admin/Contact"
            element={
              <Suspense fallback={<Loader />}>
                <EditContact />
              </Suspense>
            }
          />
          <Route
            path="/admin/editinfo"
            element={
              <Suspense fallback={<Loader />}>
                <EditInfo />
              </Suspense>
            }
          />
          <Route
            path="/admin/userquery"
            element={
              <Suspense fallback={<Loader />}>
                <UserAllQuery />
              </Suspense>
            }
          />
          <Route
            path="/admin/award"
            element={
              <Suspense fallback={<Loader />}>
                <AddAward />
              </Suspense>
            }
          />
          <Route
            path="/admin/transactions/:email"
            element={
              <Suspense>
                <Transactions />
              </Suspense>
            }
          />
          <Route
            path="/admin/child-list/:email"
            element={
              <Suspense>
                <ChiildList />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<Loader />}>
                <PageNotFound />
              </Suspense>
            }
          />
        </Routes>
        <div className="SpeedContainer">
          <SpeedDials />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
