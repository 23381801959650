import { useState } from "react";
import { BASEURL, subsError } from "../constant/constant";

const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [response, setResponse] = useState();

  const token = JSON.parse(localStorage.getItem("LogUser"));

  const fetchData = async (config) => {
    setLoading(true);
    try {
      const {
        body,
        headers = { "Content-Type": "application/json", Authorization: token },
        method,
        url,
      } = config;
      const res = await fetch(`${BASEURL}${url}`, {
        method: method,
        headers,
        body: JSON.stringify(body),
      });
      const result = await res.json();
      setResponse(result);

      return result;
    } catch (error) {
      // console.log("error is:",error);
      setError(subsError);
    } finally {
      setLoading(false);
    }
  };

  return [response, loading, error, fetchData];
};

export default useFetch;
